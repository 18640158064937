<template>
  <div>
    <div class="text-h6 font-weight-medium mb-8">
      <div class="secondary-font">{{ $t("bulksms.api.title") }}</div>
    </div>
    <div class="d-flex justify-center">
      <div style="width: 600px;">
        <v-card max-width="600" min-width="300" class="pb-4">
          <v-card-title class="px-7">
            <div class="font-weight-bold text-subtitle-1">
              <div class="secondary-font">{{ $t("bulksms.api.parameters.title") }}</div>
            </div>
          </v-card-title>
          <v-card-subtitle
            class="grey--text px-7 text-body-2"
          >{{ $t("bulksms.api.parameters.description") }}</v-card-subtitle>
          <v-card-text class="mt-4 px-7">
            <div class="mb-1 font-weight-bold">{{ $t('bulksms.api.fields.endpoint.title') }}</div>
            <v-text-field
              class="font-weight-bold"
              :placeholder="$i18n.t('security.fields.api_key.title')"
              :value="endpoint"
              autocomplete="off"
              outlined
              dense
              append-icon="mdi-content-copy"
              @click:append="$copyText(endpoint)"
              readonly
              hide-details
            ></v-text-field>
             <div class="mb-1 mt-2 font-weight-bold">{{ $t('security.fields.api_key.description') }}</div>
            <v-text-field
              class="font-weight-bold"
              :placeholder="$i18n.t('security.fields.api_key.title')"
              :value="$auth.user().apiKey"
              autocomplete="off"
              outlined
              dense
              append-icon="mdi-content-copy"
              @click:append="$copyText($auth.user().apiKey)"
              readonly
              hide-details
            ></v-text-field>
            <div class="mb-1 mt-2 font-weight-bold">{{ $t('security.fields.secret.description') }}</div>
            <v-text-field
              class="font-weight-bold"
              :placeholder="$i18n.t('security.fields.secret.title')"
              :value="$auth.user().secret"
              autocomplete="off"
              outlined
              dense
              append-icon="mdi-content-copy"
              @click:append="$copyText($auth.user().secret)"
              readonly
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-center d-flex">
            <v-btn tag="a" :href="documentation" target="_blank" color="primary" class="px-4">{{ $t('btn.documentation') }}</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
const endpoint = process.env.VUE_APP_BULKSMS_ENDPOINT || "https://portail.lmtgroup.com/providers/bulksms"
const documentation = process.env.VUE_APP_BULKSMS_ENDPOINT_DOC || "https://documenter.getpostman.com/view/1366868/TzCS3kPp"

export default {
  data: () => ({
    endpoint: endpoint,
    documentation: documentation
  })
};
</script>
        