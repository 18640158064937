<template>
  <div>
    <bulksms-api></bulksms-api>
  </div>
</template>

<script>
import BulksmsApi from './../../../components/bulksms/api/index';
export default {
  data: () => ({
  }),
  components: {
    BulksmsApi
  }
}
</script>